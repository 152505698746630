import {ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, ViewChild} from '@angular/core';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import {AcInputContainerComponent} from '../ac-input-container/ac-input-container.component';
import {DateFormat} from '../../utils/date-time-formatter';
import {GeneralService} from '../../services/general.service';
import {DateTime} from 'luxon';
import {MatFormFieldControl} from '@angular/material/form-field';
import {SchemaHelperService} from '../../services/schema-helper.service';

@Component({
    selector: 'ac-time-picker',
    templateUrl: './ac-time-picker.component.html',
    styleUrls: ['./ac-time-picker.component.less'],
})
export class AcTimePickerComponent {

    @Output() timeStringChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() set timeString(value: string) {
        this.model.visibleSelectedTimeForComponent = value;
        this.onTimeChange(value);
    }

    colorTheme = GeneralService.statusColors;
    theme: NgxMaterialTimepickerTheme = {
        container: {
            bodyBackgroundColor: this.colorTheme.systemBackground,
            buttonColor: this.colorTheme.primary,
        },
        dial: {
            dialBackgroundColor: this.colorTheme.primary,

        },
        clockFace: {
            clockFaceBackgroundColor: '#fff',
            clockHandColor: this.colorTheme.primary,
        }
    };

    @ViewChild(MatFormFieldControl, {static: true}) matFormFieldControl: MatFormFieldControl<any>;
    @ViewChild(AcInputContainerComponent, {static: true}) inputContainer: AcInputContainerComponent;


    model = {visibleSelectedTime: '', visibleSelectedTimeForComponent: ''};
    _selectedTime = DateTime.now();
    @Input() set selectedTime(selectedTime: DateTime) {
        this._selectedTime = selectedTime;
        this.model.visibleSelectedTime = selectedTime.toFormat(DateFormat.timeNoSec)
    }

    @Output() selectedTimeChanged = new EventEmitter<DateTime>();

    @Input() minutesGap: number;
    @Input() label = '';
    @Input() formFieldName: string;
    @Input() hourOnly = false;
    @Input() end24 = false;
    @Input() minTime: DateTime;


    constructor(private ref: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.inputContainer?.initializeMaterialField(this.matFormFieldControl);
    }

    validateTime = (errors, auxModel) => {
        try {
            DateTime.fromFormat(this.model.visibleSelectedTime, DateFormat.timeNoSec)
        } catch (e) {
            errors.push(SchemaHelperService.buildErrorItem({
                inputName: this.formFieldName || 'model.visibleSelectedTime',
                message: 'Time is not valid'
            }));
        }
        setTimeout(() => {
            this.ref.detectChanges();
        })
    };

    onTimeChange = ($event: any) => {
        this.model.visibleSelectedTime = $event;
        this.model.visibleSelectedTimeForComponent = $event;
        if (this.end24 && $event === '00:00') {
            this.model.visibleSelectedTime = '24:00';
            this.model.visibleSelectedTimeForComponent = '24:00';
        }

        this.timeStringChange.emit(this.model.visibleSelectedTimeForComponent);

        try {
            const newDateTime = DateTime.fromFormat($event, DateFormat.timeNoSec)
            this._selectedTime = this._selectedTime.set({hour: newDateTime.hour, minute: newDateTime.minute})
            this.selectedTimeChanged.emit(this._selectedTime);
        } catch (e) {

        }
    }

    visibleSelectedTimeChanged($event: any) {
        this.model.visibleSelectedTime = $event;

        try {
            DateTime.fromFormat(this.model.visibleSelectedTime, DateFormat.timeNoSec)
            this.model.visibleSelectedTimeForComponent = $event;
            this.onTimeChange($event)
        } catch (e) {

        }
    }

    formatTime = (time: DateTime) => {
        if (!time) {
            return;
        }
        return time.toFormat(DateFormat.timeNoSec);
    };
}

