import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {InfraModule} from 'ac-infra';
import {OVOCServicesModule} from './ovoc.services.module';
import {WebSocketService} from './common/services/ws/ws.service';
import {PipesModule} from './common/pipes/pipes.module';
import {LogoDisplayModule} from './common/components/brand-logo/logo-display.module';
import {AcSplashScreenModule} from './common/components/ac-splash-screen/ac-splash-screen.module';
import {ExceptionHandlerService} from './common/services/errors/exception-handler.service';
import {NgxMatomoTrackerModule} from '@ngx-matomo/tracker';

@NgModule({
    declarations: [
        AppComponent
        // DashboardComponent,
        // CallflowComponent,
        // AdministrationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        InfraModule.forRoot(),
        NgxMatomoTrackerModule.forRoot({
            siteId: 2,
            trackerUrl: 'https://ovoc.matomo.cloud/',
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            maxAge: 10,
        }),
        AppRoutingModule,
        PipesModule,
        LogoDisplayModule,
        OVOCServicesModule,
        AcSplashScreenModule
    ],
    exports: [],
    providers: [{provide: ErrorHandler, useClass: ExceptionHandlerService}, WebSocketService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
