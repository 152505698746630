import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {AcDropDownComponent, SessionStorageService} from 'ac-infra';
import {WsEntitiesService} from '../../../services/communication/ws-entities.service';
import {AuthorizationService} from "../../../services/authorization.service";
import {forOwn, unionBy} from "lodash";


export type FilterStateType = 'pinned' | 'unpinned';
export type FilterStorageName = 'globalScopeFilter' | 'tenantScopeFilter';

export interface FilterChangedProperties {
    type: string;
    filter: any;
}

@Injectable({providedIn: 'root'})
export class FilterState {


    static readonly UNPINNED_STATE: FilterStateType = 'unpinned';
    static readonly PINNED_STATE: FilterStateType = 'pinned';
    static readonly FILTER_TYPES = ['pinned', 'unpinnedNetworkFilter', 'unpinnedAlarmsFilter', 'unpinnedQOEFilter', 'unpinnedPMFilter', 'unpinnedUsersFilter'];
    static readonly GLOBAL_FILTER_NAME: FilterStorageName = 'globalScopeFilter';
    static readonly TENANT_FILTER_NAME: FilterStorageName = 'tenantScopeFilter';
    static readonly GROUP_TYPE_LIST = ['topologyGroups', 'endpointsGroups', 'customers'];

    stateEventMap: any;
    filterOpenedBefore = false;

    private filterTypeChangedSubject = new Subject<any>();
    filterTypeChanged$ = this.filterTypeChangedSubject.asObservable();

    emitFilterChange = (context: any) => {
        this.filterTypeChangedSubject.next(context);
    }

    private filterChangedSubject: Subject<FilterChangedProperties> = new Subject<FilterChangedProperties>();
    filterChanged$: Observable<FilterChangedProperties> = this.filterChangedSubject.asObservable();

    private topologyFilterChangedSubject: Subject<any> = new Subject<any>();
    topologyFilterChanged$ = this.topologyFilterChangedSubject.asObservable();

    private timeRangeFilterChangedSubject: Subject<any> = new Subject<any>();
    timeRangeFilterChanged$ = this.timeRangeFilterChangedSubject.asObservable();

    private setFilterUpdateSubject: Subject<any> = new Subject<any>();
    setFilterUpdate$ = this.setFilterUpdateSubject.asObservable();

    currentFilterDropdown: AcDropDownComponent;

    constructor(private router: Router, private wsEntitiesService: WsEntitiesService) {
    }

    public executeTimeRangeFilterChanged = (item) => this.timeRangeFilterChangedSubject.next(item);

    public executeTopologyFilterChanged = (topology) => this.topologyFilterChangedSubject.next(topology);

    public executeFilterChanged = (filterProp: FilterChangedProperties) => this.filterChangedSubject.next(filterProp);

    public executeFilterUpdate = (filter) => this.setFilterUpdateSubject.next(filter);

    public registerEvents = (stateToEventMap) => this.stateEventMap = stateToEventMap;

    public get = (eventName) => {
        const filter = {};

        this.appendPinnedFilter(filter);
        this.appendUnpinnedFilter(eventName, filter);

        return filter;
    };

    stateStartEventHandler = (toState, filter) => {
        const eventName = this.findMatchingEventName(toState);
        const currentRouteEventName = this.findMatchingEventName(this.router.url);

        if (eventName && filter && filter.auxiliary && filter.auxiliary.filter) {
            const oldUnpinned = this.getStorageData(FilterState.UNPINNED_STATE, eventName);
            const newUnpinned = eventName === currentRouteEventName ? _.extend(oldUnpinned, filter.auxiliary.filter) : filter.auxiliary.filter;
            this.setUnpinned(eventName, newUnpinned);

            const currentFilter = this.get(eventName);
            if (eventName === currentRouteEventName) {
                this.executeFilterUpdate(currentFilter);
            }
        }
    };

    setFilterToStorage = (event: string, filter, savePinned) => {
        savePinned && this.setPinned(this.extractPinnedFilter(filter));
        this.setUnpinned(event, this.extractUnpinnedFilter(filter));
    };

    deleteAllFilterStatesFromStorage() {
        this.setPinned();
        this.setUnpinned('NetworkFilter');

        Object.getOwnPropertyNames(sessionStorage).forEach((key) => {
            if (key.startsWith(FilterState.UNPINNED_STATE)) {
                sessionStorage.removeItem(key);
            }
        });
    }

    moveFiltersIn = (newTenantScope) => {
        let filterResult: any = {};
        let storedFilter = this.getAllStorageFilter(FilterState.GLOBAL_FILTER_NAME);

        const moveFromOneTenantToOtherTenantScope = newTenantScope !== -1 && AuthorizationService.tenantScope;
        if(moveFromOneTenantToOtherTenantScope){
            storedFilter = this.getAllStorageFilter(FilterState.TENANT_FILTER_NAME);
        }

        FilterState.FILTER_TYPES.forEach((filterTabType) => {
            forOwn(storedFilter?.[filterTabType], (filterTypeObject, filterTypeName) => {
                filterResult[filterTabType] = filterResult[filterTabType] || {};
                this.handleFilterAfterScopeChanged(filterResult, filterTypeObject, filterTypeName, filterTabType, newTenantScope);
            })
        });

        SessionStorageService.setData(FilterState.TENANT_FILTER_NAME, filterResult);
    }

    moveFiltersOut = () => {
        let filterResult: any = {};
        const storedTenantFilter = this.getAllStorageFilter(FilterState.TENANT_FILTER_NAME);
        const storedGlobalFilter = this.getAllStorageFilter(FilterState.GLOBAL_FILTER_NAME);

        FilterState.FILTER_TYPES.forEach((filterTabType) => {
            //first delete the tenantScope ids from the result
            forOwn(storedGlobalFilter?.[filterTabType], (type, filterTypeName) => {
                filterResult[filterTabType] = filterResult[filterTabType] || {};

                if(['Topology', 'Groups'].includes(filterTypeName)){
                    forOwn(type,(entities, entityName)=>{
                        entities.forEach((entity) => {
                            if((entityName === 'tenant' && AuthorizationService.tenantScope !== entity.id) ||
                                (['region', 'device', 'site', 'link'].includes(entityName) && AuthorizationService.tenantScope !== entity.tenantId)){
                                this.pushEntityToFilterResult(filterResult, filterTabType, filterTypeName, entityName, entity);
                            }else if(FilterState.GROUP_TYPE_LIST.includes(entityName)){
                                const group = this.getGroupFromWsEntities(entityName, entity);

                                if(group.tenantId !== AuthorizationService.tenantScope){
                                    this.pushEntityToFilterResult(filterResult, filterTabType, filterTypeName, entityName, entity);
                                }
                            }
                        })
                    });
                }
            });

            //second merge the result with the tenantFilter
            forOwn(storedTenantFilter?.[filterTabType], (type, filterTypeName) => {
                if(['Topology', 'Groups'].includes(filterTypeName)){
                    filterResult[filterTabType][filterTypeName]= filterResult[filterTabType][filterTypeName] || {};
                    forOwn(type, (entities, entityName) => {
                        if(!filterResult[filterTabType][filterTypeName]?.[entityName]){
                            filterResult[filterTabType][filterTypeName][entityName] = [];
                        }
                        filterResult[filterTabType][filterTypeName][entityName] = filterResult[filterTabType][filterTypeName][entityName].concat(entities);
                    })

                }else{
                    filterResult[filterTabType] = filterResult[filterTabType] || {};

                    filterResult[filterTabType][filterTypeName] = type;
                }
            });
        });

        SessionStorageService.setData(FilterState.GLOBAL_FILTER_NAME, filterResult);
    }



    private handleFilterAfterScopeChanged = (filterResult, filterTypeObject, filterTypeName, filterTabType, tenantScope) => {
        if(filterTypeName === 'Topology'){
            const entitiesNamesList = ['tenant', 'region', 'device', 'site', 'link'];
            this.addEntityToFilterObject(entitiesNamesList, filterTypeObject, filterTypeName, filterResult, filterTabType, tenantScope);
        }else if(filterTypeName === 'Groups') {
            this.addEntityToFilterObject(FilterState.GROUP_TYPE_LIST, filterTypeObject, filterTypeName, filterResult, filterTabType, tenantScope);
        }else if(filterTypeName.includes('moreFilters') && filterTypeObject?.tenants?.length > 0){
            filterTypeObject.tenants.forEach((tenantId) => {
                if(tenantId === tenantScope){
                    filterResult[filterTabType][filterTypeName] = filterResult[filterTabType][filterTypeName] || {};
                    filterResult[filterTabType][filterTypeName].tenants = filterResult[filterTabType][filterTypeName].tenants || [];
                    filterResult[filterTabType][filterTypeName].tenants.push(tenantId);
                }
            })
        }else{
            filterResult[filterTabType][filterTypeName] = filterTypeObject;
        }
    }

    private addEntityToFilterObject = (entitiesNamesList, filterTypeObject, filterTypeName, filterResult, filterTabType, tenantScope) => {
        entitiesNamesList.forEach((entityName) => {
            if (filterTypeObject?.[entityName]?.length > 0) {
                filterTypeObject[entityName].forEach((entity) => {
                    let entityObj;
                    if(FilterState.GROUP_TYPE_LIST.includes(entityName)){
                        entityObj = this.getGroupFromWsEntities(entityName, entity);
                    }else{
                        entityObj = entity;
                    }

                    if((entityName === 'tenant' && entityObj.id === tenantScope) || (entityObj.tenantId === tenantScope)){
                        filterResult[filterTabType][filterTypeName] = filterResult[filterTabType][filterTypeName] || {};
                        this.pushEntityToFilterResult(filterResult, filterTabType, filterTypeName, entityName, entity);
                    }
                });
            }
        })
    }

    private pushEntityToFilterResult = (filterResult, filterTabType, filterTypeName, entityName, entity) => {
        filterResult[filterTabType][filterTypeName] = filterResult[filterTabType][filterTypeName] || {};

        if(!filterResult[filterTabType]?.[filterTypeName]?.[entityName]){
            filterResult[filterTabType][filterTypeName] = filterResult[filterTabType][filterTypeName] || {};
            filterResult[filterTabType][filterTypeName][entityName] = [];
        }

        if(entity && _.isNumber(entity) && !FilterState.GROUP_TYPE_LIST.includes(entityName)){
            entity = this.wsEntitiesService.getEntity(WsEntitiesService.entitiesNamesMapper[entityName], entity);
        }

        entity && filterResult[filterTabType][filterTypeName][entityName].push(entity);
    }

    private getGroupFromWsEntities = (groupName, groupId) => {
        const entityType = groupName === 'customers' ? 'customers' : 'groups';
        return this.wsEntitiesService.getEntity(entityType, groupId) || {};
    }

    private appendPinnedFilter = (filter) => {
        this.appendFilter(FilterState.PINNED_STATE, filter, FilterState.PINNED_STATE);
    };

    private appendUnpinnedFilter = (eventName, filter) => {
        this.appendFilter(FilterState.UNPINNED_STATE + eventName, filter, FilterState.UNPINNED_STATE);
    };

    private appendFilter = (storeKey, filter, fieldName) => {
        const partial = this.getStorageData(storeKey);

        Object.getOwnPropertyNames(partial).forEach((type) => {
            filter[type] = filter[type] || {};
            filter[type][fieldName] = partial[type];
        });
    };

    private setPinned(newPinned = {}) {
        const oldPinned = this.getStorageData(FilterState.PINNED_STATE);
        this.setStorageData(FilterState.PINNED_STATE, newPinned);

        this.compareFilters(oldPinned, newPinned);
    }

    private setUnpinned(event: string, newUnpinned = {}) {
        const oldUnpinned = this.getStorageData(FilterState.UNPINNED_STATE, event);
        this.setStorageData(FilterState.UNPINNED_STATE + event, newUnpinned);

        if (event === 'NetworkFilter') {
            this.compareFilters(oldUnpinned, newUnpinned);
        }
    }

    private findMatchingEventName = (stateName) => {
        let parentState;

        Object.getOwnPropertyNames(this.stateEventMap).forEach((state) => {
            if (stateName.startsWith(state)) {
                parentState = (!parentState || (state.length > parentState.length)) ? state : parentState;
            }
        });

        if (parentState) {
            return this.stateEventMap[parentState];
        }
    };

    private extractPinnedFilter = (filter) => this.extractFilter(filter, FilterState.PINNED_STATE);

    private extractUnpinnedFilter = (filter) => this.extractFilter(filter, FilterState.UNPINNED_STATE);

    private extractFilter = (filter, pinType) => {
        const extracted = {};

        Object.getOwnPropertyNames((filter || {})).forEach((type) => {
            if (filter[type][pinType]) {
                extracted[type] = filter[type][pinType];
            }
        });

        return extracted;
    };

    private compareFilters(oldFilter: any, newFilter: any) {
        if (!_.isEqual(oldFilter.timeRange, newFilter.timeRange)) {
            // Update status
            this.wsEntitiesService.filteredUpdate('updateStatus');
        }
        delete oldFilter.timeRange;
        delete newFilter.timeRange;
        if (!_.isEqual(oldFilter, newFilter)) {
            // Update filteredIds
            this.wsEntitiesService.filteredUpdate('updateFilteredIds');
        }
    }



    getStorageData = (storeKey: FilterStateType, postfix = '') => {
        const key = storeKey + postfix;
        const storedFilter = this.getAllStorageFilter();

        return storedFilter?.[key] || {};
    }

    setStorageData = (key, filter, filterStorageName?: FilterStorageName) => {
        const storageName = filterStorageName ||  FilterState.getStorageFilterName();
        const storedFilter = this.getAllStorageFilter(filterStorageName);
        if(storedFilter){
            storedFilter[key] = filter;

            SessionStorageService.setData(storageName, storedFilter);
        }
    }

    getAllStorageFilter = (filterStorageName?: FilterStorageName) => {
        const storageName = filterStorageName || FilterState.getStorageFilterName();
        return SessionStorageService.getData(storageName) || {};
    }

    static getStorageFilterName = (tenantScope = undefined) => {
        const isGlobalScope = tenantScope === undefined ? AuthorizationService.isGlobalScope() : (tenantScope === null);
        return isGlobalScope ? FilterState.GLOBAL_FILTER_NAME : FilterState.TENANT_FILTER_NAME;
    }
}
