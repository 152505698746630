<mat-calendar class="calendar"
              [headerComponent]="acDatePickerHeader"
              [selected]="_selectedRange"
              (selectedChange)="onSelect($event)"
              #calendar>
</mat-calendar>

<div class="calendar-footer flexColumnParent">
    <div class="ac-range-calendar-time-picker flexRowParent">
        <ac-time-picker label="start" [selectedTime]="_startTimeState"
                        #startTime
                        (selectedTimeChanged)="onStartTimeChange($event)"
        ></ac-time-picker>

        <ac-time-picker label="end" [minTime]="_startTimeState | acFunc: isMinDateNeeded"
                        #endTime
                        [selectedTime]="_endTimeState"
                        (selectedTimeChanged)="onEndTimeChange($event)"
        ></ac-time-picker>
    </div>
    <div class="ac-calendar-actions flexRowParent">
        <ac-button class="clear" (click)="cancel.emit()">Cancel</ac-button>
        <ac-button (click)="!startTime.inputContainer.error && !endTime.inputContainer.error && apply.emit(_selectedRange)">Apply</ac-button>
    </div>
</div>


